import { decamelizeKeys } from "humps";
import qs from "qs";

import api from "~/utils/api/api";
import type { Locale } from "~/utils/interfaces/Locale";
import type { Heatmap, HeatmapRequestPayload } from "~/utils/interfaces/NurseBlocktables";
import type { RequestError, RequestResponse } from "~/utils/interfaces/request";
import type { TimeBlock, TimeblockUnavailableItems } from "~/utils/interfaces/Timeblock";

export async function getAllCountryTimeblocks(country: Locale, gridName = ""): Promise<TimeBlock[] | undefined> {
  try {
    const res = await api.get<TimeBlock[]>(
      `/v2/timeblocks/?${qs.stringify({
        country,
        grid_name: gridName,
      })}`,
    );
    return res.data;
  } catch (error) {
    return undefined;
  }
}

export async function getAvailabilitiesHeatmap({
  sectorId,
  country,
  fingerprintAvailable = null,
  daysRange = 14,
  medicalActionIds = null,
  medicalServiceIds = null,
  nurseSpecialtiesIds = null,
  filterByDate = false,
  selectedDate = null,
}: HeatmapRequestPayload): Promise<RequestResponse<Heatmap>> {
  try {
    const res = await api.post<Heatmap>("/v2/timeblocks/nurse-availabilities-heatmap/", {
      country: country,
      days_range: daysRange,
      sector_id: sectorId,
      medical_action_ids: medicalActionIds,
      medical_service_ids: medicalServiceIds,
      specialties_ids: nurseSpecialtiesIds,
      fingerprint_available: fingerprintAvailable,
      filter_by_date: filterByDate,
      date: selectedDate,
    });
    return { data: res.data };
  } catch (error) {
    return { error } as RequestError;
  }
}

export async function getTimeblocksUnavailableItems({ countryCode }: { countryCode: string }) {
  try {
    const data = decamelizeKeys({ countryCode });
    const res = await api.get<TimeblockUnavailableItems>(
      `/v2/time-availability/timeblocks-unavailable-items/?${qs.stringify(data)}`,
    );
    return { data: res.data };
  } catch (error) {
    return { error } as RequestError;
  }
}
